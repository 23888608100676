var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"importacoes-historico","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-format-list-group-plus","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Manutenção AL/SE/LD :: Importações ")])]},proxy:true}])},[_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-nova-importacao","color":"primary","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('importacoes/add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Nova importação ")],1),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-importacao",staticStyle:{"max-width":"250px"},attrs:{"id":"importacao-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"importacoes-table",attrs:{"headers":_vm.headers,"items":_vm.importacoes,"search":_vm.search,"headerProps":{
            sortByText: 'Ordenar Por',
          },"footer-props":{
            itemsPerPageText: 'Itens por Página',
          },"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"mobile-breakpoint":800,"no-data-text":"Nenhuma importação de dados encontrada","no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addLeadingZero")(item.id))+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",attrs:{"id":"logs-importacao","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push(
                  ("/dados/manutencao-tecnicos/importacoes/logs/" + (item.id))
                )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-tree ")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }