<template>
  <v-container id="importacoes-historico" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-format-list-group-plus"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Manutenção AL/SE/LD :: Importações
            </div>
          </template>

          <v-btn
            id="btn-nova-importacao"
            color="primary"
            dark
            elevation="1"
            class="mt-5"
            style="float:left"
            relative
            text
            medium
            @click="$router.push('importacoes/add')"
          >
            <v-icon left size="30px">
              mdi-plus-circle
            </v-icon>
            Nova importação
          </v-btn>

          <v-text-field
            id="importacao-historico-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-importacao"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <v-divider class="mt-10" />

          <v-data-table
            class="importacoes-table"
            :headers="headers"
            :items="importacoes"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por',
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página',
            }"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            :mobile-breakpoint="800"
            no-data-text="Nenhuma importação de dados encontrada"
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.id`]="{ item }">
              {{ item.id | addLeadingZero }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <general-status :status="item.status" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                id="logs-importacao"
                min-width="0"
                class="px-1"
                fab
                icon
                x-small
                @click="
                  $router.push(
                    `/dados/manutencao-tecnicos/importacoes/logs/${item.id}`
                  )
                "
              >
                <v-icon small>
                  mdi-file-tree
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ImportacoesManutencoesTecnicosService from "@/services/ImportacoesManutencoesTecnicosService";
  import refreshDataMixins from "@/mixins/refreshDataMixins";
  import tourMixins from "@/mixins/tourMixins";

  export default {
    name: "ManutencaoTecnicosImportacoesIndex",
    mixins: [refreshDataMixins, tourMixins],
    components: {
      GeneralStatus: () => import("@/components/general/GeneralStatus.vue"),
    },
    data: () => ({
      headers: [
        {
          text: "#",
          value: "id",
        },
        {
          text: "Título",
          value: "titulo",
        },
        {
          text: "Tipo de Importação",
          value: "tipo_importacao",
        },
        {
          text: "Arquivo",
          value: "arquivo",
        },
        {
          text: "Usuário",
          value: "usuario",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          sortable: false,
          text: "Ações",
          value: "actions",
          class: "pl-4",
        },
      ],
      importacoes: [],
      search: "",
      arrTour: [
        {
          element: "#btn-nova-importacao",
          intro:
            "Clicando neste botão você poderá cadastrar novas importações.",
          scrollTo: "tooltip",
          position: "right",
        },
        {
          element: "#importacao-historico-search",
          intro:
            "Neste campo você poderá pesquisar por importações já cadastradas.",
          scrollTo: "tooltip",
          position: "bottom",
        },
        {
          element: "#logs-importacao",
          intro:
            "Clicando neste botão você será redirecionado para a tela de logs, onde será possível visualizar um resumo sobre a importação.",
          scrollTo: "tooltip",
          position: "left",
        },
      ],
      loading: false,
    }),
    mounted() {
      this.refreshData(this.getImportacoes);
    },
    methods: {
      getImportacoes() {
        this.loading = true;

        ImportacoesManutencoesTecnicosService.getImportacoes()
          .then((importacoes) => {
            this.importacoes = importacoes;
            this.iniciarTourPagina();
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro ao buscar as importações.", "", {
              position: "topRight",
            });
          })
          .finally(() => (this.loading = false));
      },
      iniciarTourPagina() {
        if (this.tourFuncCalled) return;

        let arrTourFormatted = this.importacoes.length
          ? this.arrTour
          : this.arrTour.splice(0, 2);
        this.iniciarTour(arrTourFormatted);
      },
    },
  };
</script>

<style>
  @media screen and (min-width: 800px) {
    .importacoes-table table tr td:nth-child(2) {
      max-width: 200px;
    }

    .importacoes-table table tr td:nth-child(7) {
      white-space: nowrap !important;
    }
  }

  @media screen and (max-width: 480px) {
    .bs-importacao {
      width: 235.86px !important;
    }
  }
</style>
